import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Button,
  Checkbox,
  MenuItem,
  Select,
  Grid,
  //  Container,
  Input,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { TextField } from "@material-ui/core";
import { DatabaseContext } from "../../contexts/DatabaseContext";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function SchoolStudentAddressVerification() {
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setValues,
    setDate,
    handleSubmit,
    resetForm,
    onChange,
    handleClick,
  } = useFormik({
    initialValues: {
      Street: "",
      Suburb: "",
      State: "WA",
      Postcode: "",
    },
    validationSchema: Yup.object({
      //ID: Yup.number().integer(),
      Street: Yup.string().required("Please Enter your street name"),
      Suburb: Yup.string().required("Please Enter your suburb"),
      State: Yup.string().required("Please choose your state"),
      Postcode: Yup.string().required().max(4, "Postcode can only be 4 digits"),
    }),
  });

  const handleAddressSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setAddress(results[0].formatted_address);
    var addr = results[0].formatted_address.split(",");
    var line2 = addr[1].trim().split(" ");

    values.Street = `${addr[0].toString()}`;
    values.Suburb = `${line2[0].toString()}`;
    values.State = `${line2[1].toString()}`;
    values.Postcode = `${line2[2].toString()}`;
  };

  const back = async () => {
    navigate("/schoolstudentlookup", { replace: true });
  };

  const next = async () => {
    if (values.Street && values.Suburb && values.State && values.Postcode) {
      setLoading(true);
      const res = await Axios.get(
        `${IDCaptureAPI}/api/sync/wonde/getStudentDetails/?siteName=${localStorage.siteName}&firstName=${localStorage.firstName}&lastName=${localStorage.surname}&dateOfBirth=${localStorage.DateOfBirth}`
      );

      if (
        values.Street.toUpperCase() ===
          res.data.data.contact_details.data.addresses.home.street.toUpperCase() &&
        values.Suburb.toUpperCase() ===
          res.data.data.contact_details.data.addresses.home.town.toUpperCase() &&
        values.State.toUpperCase() ===
          res.data.data.contact_details.data.addresses.home.county.toUpperCase() &&
        values.Postcode.toUpperCase() ===
          res.data.data.contact_details.data.addresses.home.postcode.toUpperCase()
      ) {
        localStorage.setItem("ExternalID", res.data.data.mis_id);
        localStorage.setItem(
          "ResStreet",
          res.data.data.contact_details.data.addresses.home.street
        );
        localStorage.setItem(
          "ResSuburb",
          res.data.data.contact_details.data.addresses.home.town
        );
        localStorage.setItem(
          "ResState",
          res.data.data.contact_details.data.addresses.home.county
        );
        localStorage.setItem(
          "ResPostcode",
          res.data.data.contact_details.data.addresses.home.postcode
        );

        res.data.data.groups.data.forEach((element) => {
          if (element.type == "YEAR") {
            localStorage.setItem("YearGroup", element.code);
          }
        });

        setLoading(false);
        navigate("/schoolcontactdetails", { replace: true });
      } else {
        setLoading(false);
        navigate("/addressverificationfailed", { replace: true });
      }
    } else {
      setLoading(false);
      handleSubmit();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("siteName");
      localStorage.removeItem("siteCode");
      localStorage.removeItem("siteOrderTypeID");
      localStorage.removeItem("firstName");
      localStorage.removeItem("middleName");
      localStorage.removeItem("surname");
      localStorage.removeItem("DateOfBirth");
      window.location.href = "/buyonline";
    }, 7200000);
  }, []);

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Student Address Confirmation
        </h>
        <br />
        <br />
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            {" "}
          </Grid>
          <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
            Due to student validation being done through the school, please
            enter the address that the school has on file for the student who
            you are ordering the Smartrider card for.
          </p>
          <br />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={
              window.innerWidth > 775
                ? { marginLeft: "35%", marginRight: "35%" }
                : {
                    marginLeft: "10%",
                    marginRight: "10%",
                  }
            }
          >
            <Grid item xs={12} sm={12} md={12} padding={{ paddingTop: "20px" }}>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleAddressSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    {/* <input
                    {...getInputProps({ placeholder: "Enter Address" })}
                    style={{ width: "99.5%", height: "50px" }}
                  /> */}
                    <TextField
                      name="address"
                      variant="outlined"
                      fullWidth
                      label="Address Search"
                      value={{ address }}
                      {...getInputProps({ placeholder: "Enter Address" })}
                      required
                    ></TextField>
                    <div>
                      {loading ? <div>...loading</div> : null}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#0099d8"
                            : "#266798",
                          width: "99%",
                        };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    padding={{ paddingTop: "20px" }}
                  >
                    <TextField
                      id="Street"
                      name="Street"
                      label="House Number & Street"
                      value={values.Street}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.Street && errors.Street}
                      helperText={
                        errors.Street && touched.Street && errors.Street
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      style={{
                        width: "100%",
                      }}
                    ></TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    padding={{ paddingTop: "20px" }}
                  >
                    <TextField
                      id="Suburb"
                      name="Suburb"
                      label="Suburb"
                      value={values.Suburb}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.Suburb && errors.Suburb}
                      helperText={
                        errors.Suburb && touched.Suburb && errors.Suburb
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      style={{
                        width: "100%",
                      }}
                    ></TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    padding={{ paddingTop: "20px" }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="State"
                          name="State"
                          label="State"
                          value={values.State}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.State && errors.State}
                          helperText={
                            errors.State && touched.State && errors.State
                          }
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                          style={{
                            width: "100%",
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="Postcode"
                          name="Postcode"
                          label="Postcode"
                          value={values.Postcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Postcode && errors.Postcode}
                          helperText={
                            errors.Postcode &&
                            touched.Postcode &&
                            errors.Postcode
                          }
                          InputLabelProps={{ shrink: true }}
                          style={{
                            width: "100%",
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {loading > 0 ? (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4}></Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p style={messageStyle}>Loading Data, Please Wait...</p>
                </Grid>
                <Grid item xs={12} sm={12} md={4}></Grid>
              </Grid>
            ) : (
              <></>
            )}
            <br />
            <br />
            <Button
              style={
                window.innerWidth > 775
                  ? {
                      backgroundColor: "#D3212D",
                      color: "#ffffff",
                      fontSize: 20,
                      marginLeft: "15%",
                      width: "30%",
                    }
                  : {
                      backgroundColor: "#D3212D",
                      color: "#ffffff",
                      marginLeft: "30%",
                      fontSize: "110%",
                    }
              }
              onClick={back}
            >
              Back
            </Button>
            <Button
              style={
                window.innerWidth > 775
                  ? {
                      backgroundColor: "green",
                      color: "#ffffff",
                      marginLeft: "5%",
                      fontSize: 20,
                      width: "30%",
                    }
                  : {
                      backgroundColor: "green",
                      color: "#ffffff",
                      marginLeft: "2%",
                      fontSize: "110%",
                    }
              }
              onClick={next}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "5%" }}></Grid>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const listStyle = {
  fontSize: 18,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "30%",
  paddingRight: "30%",
};

const listStyle1 = {
  fontSize: "140%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const messageStyle = {
  fontSize: 18,
  color: "#ffffff",
  textAlign: "centre",
  alignItems: "center",
  textAlign: "center",
};

const Container = styled.div``;

const Actions = styled.div``;

export default SchoolStudentAddressVerification;
